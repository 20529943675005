import { Box, createStyles, getStylesRef, Stack, Text } from '@mantine/core';
import React from 'react';

import {
  ControlledTextEditor,
  TextEditorProps,
  useTextEditor,
} from '@portals/core';
import { VerticalScrollBar } from '@portals/scrollbar';

import { NoteEmptyState } from './NoteEmptyState';
import { useCurrentSpace } from '../../../overview.hooks';

interface NoteWidgetProps {
  note?: string | null;
}

export function NoteWidget({ note }: NoteWidgetProps) {
  const { classes } = useStyles();

  const editor = useTextEditor(
    {
      content: note,
      editable: false,
    },
    [note]
  );

  return (
    <Stack className={classes.container} p={32}>
      <Text size="md" color="gray.5">
        Note
      </Text>

      {note ? (
        <VerticalScrollBar renderView={(props) => <Box {...props} />}>
          <ControlledTextEditor styles={editorTextStyle} editor={editor} />
        </VerticalScrollBar>
      ) : (
        <NoteEmptyState />
      )}
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.white,
    borderRadius: 20,
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    gridTemplateColumns: '1fr',
    width: '100%',
    position: 'relative',

    '&:hover': {
      [`.${getStylesRef('edit')}`]: {
        opacity: '1 !important',
        pointerEvents: 'unset',
      },
    },
  },
  note: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    fontSize: theme.fontSizes.sm,
    color: theme.fn.primaryColor(),
    weight: 500,
  },
  edit: {
    ref: getStylesRef('edit'),
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    right: theme.spacing.md,
    top: theme.spacing.md,
  },
}));

const editorTextStyle: TextEditorProps['styles'] = (theme) => ({
  root: {
    borderWidth: 0,
  },
  typographyStylesProvider: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    fontSize: theme.fontSizes.sm,
    color: theme.fn.primaryColor(),
    weight: 500,
  },
});

export function NoteWidgetWrapper() {
  const space = useCurrentSpace();

  return <NoteWidget note={space?.note} />;
}
