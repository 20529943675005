import { RichTextEditor, RichTextEditorProps } from '@mantine/tiptap';
import { Link } from '@tiptap/extension-link';
import { Underline as UnderlineExtension } from '@tiptap/extension-underline';
import { EditorOptions, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import React, { DependencyList } from 'react';

import {
  BoldIcon,
  BulletListIcon,
  ItalicIcon,
  LinkIcon,
  OrderedListIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from './icons';

export interface TextEditorProps
  extends Omit<RichTextEditorProps, 'editor' | 'children'> {
  editorParams: Omit<Partial<EditorOptions>, 'extensions'>;
}

export function useTextEditor(
  {
    content,

    ...editorParams
  }: Omit<Partial<EditorOptions>, 'extensions'>,
  deps?: DependencyList
) {
  const editor = useEditor(
    {
      extensions: [StarterKit, UnderlineExtension, Link],
      content: content ?? '',
      ...editorParams,
    },
    deps
  );

  return editor;
}

export function TextEditor({
  editorParams,
  ...richTextEditorProps
}: TextEditorProps) {
  const editor = useEditor({
    extensions: [StarterKit, UnderlineExtension, Link],
    ...editorParams,
  });

  return (
    <RichTextEditor editor={editor} {...richTextEditorProps}>
      {editorParams?.editable !== false && (
        <RichTextEditor.Toolbar sticky stickyOffset={0}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Italic icon={ItalicIcon} />
            <RichTextEditor.Bold icon={BoldIcon} />
            <RichTextEditor.Underline icon={UnderlineIcon} />
            <RichTextEditor.Strikethrough icon={StrikethroughIcon} />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link icon={LinkIcon} />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList icon={BulletListIcon} />
            <RichTextEditor.OrderedList icon={OrderedListIcon} />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>
      )}

      <RichTextEditor.Content />
    </RichTextEditor>
  );
}

export function ControlledTextEditor({
  editor,
  ...richTextEditorProps
}: { editor: ReturnType<typeof useEditor> } & Omit<
  RichTextEditorProps,
  'editor' | 'children'
>) {
  return (
    <RichTextEditor editor={editor} {...richTextEditorProps}>
      {editor?.isEditable !== false && (
        <RichTextEditor.Toolbar sticky stickyOffset={0}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Italic icon={ItalicIcon} />
            <RichTextEditor.Bold icon={BoldIcon} />
            <RichTextEditor.Underline icon={UnderlineIcon} />
            <RichTextEditor.Strikethrough icon={StrikethroughIcon} />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link icon={LinkIcon} />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.BulletList icon={BulletListIcon} />
            <RichTextEditor.OrderedList icon={OrderedListIcon} />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>
      )}

      <RichTextEditor.Content />
    </RichTextEditor>
  );
}
