import { Button, createStyles, Stack, Text } from '@mantine/core';
import React from 'react';

import { useOverviewContext } from '../../../overview.context';
import emptyStateSrc from '../../assets/empty-state.svg';

interface NoteEmptyStateProps {
  hideAdd?: boolean;
}

export function NoteEmptyState({ hideAdd = false }: NoteEmptyStateProps) {
  const overview = useOverviewContext();
  const { classes } = useStyles();

  const onAddNote = () => {
    if (!overview.isWidgetsConfigMode) {
      overview.onEnableConfigMode();
    }

    overview.onEditWidget('note');
  };

  return (
    <Stack
      align="center"
      justify="center"
      spacing={4}
      className={classes.container}
    >
      <Text size="lg" weight={500} color="gray.8">
        Your Notes Here
      </Text>
      <Text size="md" color="gray.5">
        Add notes in your space
      </Text>

      {!hideAdd ? (
        <Button variant="subtle" onClick={onAddNote}>
          Add Note
        </Button>
      ) : null}

      <img src={emptyStateSrc} className={classes.image} />
    </Stack>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: theme.spacing.xl,
  },
  image: {
    position: 'absolute',
    transform: 'translate(100px, 10px)',
    zIndex: 0,
  },
}));
