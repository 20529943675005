import {
  Box,
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';

import { useUpdateSpace } from '@portals/api/organizations';
import {
  ControlledTextEditor,
  TextEditor,
  TextEditorProps,
  useTextEditor,
} from '@portals/core';

import { ReactComponent as NoteIcon } from '../../../pages/overview/overview-dashboard/assets/note-icon.svg';
import { NoteEmptyState } from '../../../pages/overview/overview-dashboard/widgets/note/NoteEmptyState';
import { WidgetPreviewComponentProps } from '../widget-preview.types';

export function NoteWidgetPreview({
  onClose,
  space,
  onSave,
  type,
}: WidgetPreviewComponentProps & { onClose: () => void }) {
  const [note, setNote] = useState(type === 'create' ? '' : space?.note || '');
  const updateSpace = useUpdateSpace();

  const editor = useTextEditor({
    content: note,
    editable: false,
  });

  useEffect(
    function setEditorContent() {
      editor?.commands.setContent(note);
    },
    [editor, note]
  );

  const isEmptyNote = editor?.isEmpty || editor?.getText().trim() === '';
  const { classes } = useStyles({ isEmptyNote });

  const onSubmit = () =>
    updateSpace.mutate(
      {
        spaceId: space.id,
        updatedSpace: { note },
      },
      {
        onSuccess: () => {
          onSave?.();
          onClose();
        },
      }
    );

  return (
    <>
      <LoadingOverlay visible={updateSpace.isLoading} />

      <Box className={classes.container}>
        <Box className={classes.leftColumnContainer}>
          <Group spacing="sm" align="center" pt="xl" pl="xl" mb={32}>
            <NoteIcon width={28} height={28} />

            <Text size="xl" color="blue_gray.9">
              Note Widget
            </Text>
          </Group>

          <Box className={classes.editorContainer}>
            <TextEditor
              styles={textEditorStyle}
              editorParams={{
                content: note,
                onUpdate: ({ editor }) => setNote(editor.getHTML()),
                autofocus: true,
              }}
            />
          </Box>
        </Box>

        <Box className={classes.rightColumnContainer}>
          <Stack className={classes.noteContainer}>
            <Text size="md" color="gray.5">
              Note
            </Text>

            {!isEmptyNote ? (
              <ScrollArea>
                <ControlledTextEditor styles={editorPreview} editor={editor} />
              </ScrollArea>
            ) : (
              <NoteEmptyState hideAdd />
            )}
          </Stack>
        </Box>
      </Box>

      <Group position="right" className={classes.footer} p="xl">
        <Button onClick={onClose} variant="default">
          Close
        </Button>

        <Button onClick={onSubmit}>{type === 'edit' ? 'Save' : 'Add'}</Button>
      </Group>
    </>
  );
}

const useStyles = createStyles(
  (theme, { isEmptyNote }: { isEmptyNote: boolean }) => ({
    container: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      height: 540,
      gap: 0,
      overflow: 'hidden',
    },

    footer: {
      borderTop: `1px solid ${theme.colors.gray[3]}`,
    },

    leftColumnContainer: {
      overflow: 'hidden',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'max-content 1fr',
    },

    editorContainer: {
      padding: theme.spacing.xl,
      paddingTop: 0,
      overflow: 'hidden',
      height: '100%',
    },

    rightColumnContainer: {
      backgroundColor: theme.colors.gray[1],
      display: 'grid',
      padding: '55px',
      gridTemplateRows: 'auto',
      gridTemplateColumns: '1fr',
      overflow: 'hidden',
      height: '100%',

      ...(isEmptyNote && {
        justifyContent: 'center',
        alignItems: 'center',
      }),
    },

    noteContainer: {
      display: 'grid',
      gridTemplateRows: 'min-content 1fr',
      gridTemplateColumns: '1fr',

      backgroundColor: theme.white,
      borderRadius: 20,

      padding: theme.spacing.xxl,
      overflow: 'hidden',
    },
  })
);

const textEditorStyle: TextEditorProps['styles'] = (theme) => ({
  root: {
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',

    overflow: 'hidden',
    height: '100%',
  },

  typographyStylesProvider: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    fontWeight: 500,

    overflow: 'hidden',
    height: '100%',
  },

  content: {
    overflow: 'auto',
    height: '100%',

    '.ProseMirror': {
      height: '100%',
    },
  },
});

const editorPreview: TextEditorProps['styles'] = (theme) => ({
  root: {
    borderWidth: 0,
    overflow: 'hidden',
    height: '100%',
  },

  typographyStylesProvider: {
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[9],
    fontWeight: 500,
    overflow: 'hidden',
    height: '100%',
  },

  content: {
    overflow: 'auto',
    height: '100%',
  },
});
